import type { NodeDetail } from '~/types/api'

export function createNodesRepository() {
  const { $api } = useNuxtApp()
  const { get } = baseRepository($api)

  const DOMAIN = 'nodes'

  async function getDetail(id: number) {
    return get<NodeDetail>([DOMAIN, id.toString()])
  }

  async function getTranslatedSlugs(translationOfNid: number) {
    return get<Partial<{ cs: string; en: string; de: string; fr: string }>>([
      DOMAIN,
      translationOfNid,
      'slugs',
    ])
  }

  return {
    getDetail,
    getTranslatedSlugs,
  }
}
