import type { RouteLocation } from 'vue-router'
import type { NodeDetail } from '~/types/api'
import { ApiLocale } from '~/constants/locales'
import NodeTypes from '~/constants/nodeTypes'

export default defineNuxtRouteMiddleware(async (to) => {
  const { $localePath } = useNuxtApp()

  const nid = +to.params.nid

  // decode node by nid, redirect to correct url
  try {
    const result = await createNodesRepository().getDetail(nid)

    if (result.status === 0) {
      return navigateToHomepage()
    }
    return await performRedirect(result)
  } catch (err) {
    return navigateToHomepage()
  }

  async function navigateToPage(
    name: string,
    slug: string | null = null,
    nid: number | null = null,
    language: string | null = null
  ) {
    const apiLocale = (Object.values(ApiLocale) as Array<ApiLocale>).find(
      (val) => val === language
    )
    let nuxtLocale = null
    if (apiLocale !== undefined) {
      nuxtLocale = convertApiLocale(apiLocale)
    }

    const route = { name } as RouteLocation
    if (slug !== null) {
      route.params = { slug }
    } else if (nid !== null) {
      route.params = { nid }
    }

    if (nuxtLocale === null) {
      return await navigateTo($localePath(route), { redirectCode: 301 })
    } else {
      return await navigateTo($localePath(route, nuxtLocale), {
        redirectCode: 301,
      })
    }
  }

  function navigateToHomepage() {
    return navigateToPage('index')
  }

  async function performRedirect(node: NodeDetail) {
    let routeName
    switch (node.type) {
      case NodeTypes.Designer:
        routeName = 'designer-slug'
        break
      case NodeTypes.News:
        routeName = 'news-slug'
        break
      case NodeTypes.ForMedia:
        routeName = 'press'
        break
      case NodeTypes.ProductCategory:
        routeName = 'product-lines-category-slug'
        break
      case NodeTypes.ProductLine:
        routeName = 'slug'
        break
      case NodeTypes.Reference:
        routeName = 'reference-slug'
        break
      case NodeTypes.ReferenceCategory:
        routeName = 'references-categories-nid'
        break
      case NodeTypes.Page:
        routeName = 'slug'
        break
      default:
        routeName = 'index'
    }
    if (routeName === 'index') {
      return navigateToHomepage()
    } else {
      return await navigateToPage(
        routeName,
        node.urlSlug ?? null,
        node.nid,
        node.language
      )
    }
  }
})
